const learningNavJson = [
    {
        id:0,
        value:"Javascript",
        link:"/javascript",
    },
    {
        id:1,
        value:"DBMS",
        link:"/dbms",
    },
    {
        id:2,
        value:"NodeJS",
        link:"/nodeJs",
    },
    {
        id:3,
        value:"Sequelize",
        link:"/sequelize",
    }, {
        id:4,
        value:"CPP",
        link:"/cpp",
    },
]

export default learningNavJson;